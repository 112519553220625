exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-buy-tsx": () => import("./../../../src/pages/buy.tsx" /* webpackChunkName: "component---src-pages-buy-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partnership-tsx": () => import("./../../../src/pages/partnership.tsx" /* webpackChunkName: "component---src-pages-partnership-tsx" */),
  "component---src-templates-articles-tsx": () => import("./../../../src/templates/articles.tsx" /* webpackChunkName: "component---src-templates-articles-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

